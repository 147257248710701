import { Avatar, Typography } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import clsx from 'clsx';
import { isUndefined } from 'lodash';
import { FormattedNumber } from 'react-intl';
import cmc from '../assets/coinmaketcap.png';
import coinGecko from '../assets/coingecko.png';
import dex from '../assets/dex.png';
import fuCoin from '../assets/fu-coin.png';

const SOCIALS = [
  { href: 'https://coinmarketcap.com/currencies/fu-coin/', src: cmc },
  { href: 'https://www.coingecko.com/en/coins/fu', src: coinGecko },
  {
    href: 'https://www.dextools.io/app/en/bnb/pair-explorer/0x6ed1841f8a04790bda0e624ec11c38ac51e1dd17?t=1727432630113',
    src: dex,
  },
];

export function LogoAndSocials({ tokenPrice }) {
  return (
    <div className="flex items-center w-full mt-6 gap-x-2">
      <Avatar className="!w-12 !h-12" src={fuCoin} />
      <div className="relative">
        <div className="flex items-center gap-x-1">
          <Typography variant="h4" className="uppercase">
            FU coin
          </Typography>
          {!isUndefined(tokenPrice) && (
            <span className="text-xs flex items-center">({tokenPrice})</span>
          )}
        </div>
        <SocialMedias />
      </div>
    </div>
  );
}

function SocialMedias() {
  return (
    <div className="flex items-center absolute -left-2 top-7">
      {SOCIALS.map(({ src, href }) => (
        <a href={href} target="_blank" rel="noreferrer" key={href}>
          <LazyLoadImage
            src={src}
            wrapperClassName="p-0"
            className={clsx('object-contain object-center aspect-square', {
              'w-8': src === cmc,
              'w-5': src === coinGecko,
              'w-7': src === dex,
            })}
          />
        </a>
      ))}
    </div>
  );
}
