import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import { isUndefined } from 'lodash';
import { FormattedNumber } from 'react-intl';
import AddIcon from '@mui/icons-material/Add';

export function CurrencyPrices({ wusdPrice, usdtPrice }) {
  return (
    <div className="flex flex-col">
      <div
        className={clsx(
          'w-full p-2 bg-black/30 rounded-md text-sm flex items-center justify-around'
        )}
      >
        <CurrencyPrice currency="USDT" price={usdtPrice} />
        <AddIcon className="w-4 h-4" />
        <CurrencyPrice currency="WPoint" price={wusdPrice} />
      </div>
    </div>
  );
}

function CurrencyPrice({ currency, price, isDiscount }) {
  const isUsdt = currency === 'USDT';

  return (
    <span className="flex items-center justify-center font-semibold text-sm">
      <span className={clsx(isUsdt ? 'text-usdt' : 'text-wusd')}>
        {currency} {isDiscount ? 'Discount' : 'Price'}
      </span>
      {isUndefined(price) ? (
        <CircularProgress size={20} className="ml-1.5" />
      ) : (
        <span>
          = <FormattedNumber value={price} maximumFractionDigits={2} />
        </span>
      )}
    </span>
  );
}
