export const CupIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
    <path
      fill="#ffaf40"
      d="M92.339 218.829c-21.36-4.438-41.266-16.977-56.049-35.307-14.783-18.329-22.821-40.439-22.635-62.255.195-22.86 9.284-42.694 25.594-55.848 16.309-13.153 37.62-17.834 60.001-13.184 21.36 4.438 41.265 16.977 56.048 35.307s22.822 40.439 22.636 62.255c-.195 22.86-9.284 42.694-25.594 55.847-14.299 11.532-32.438 16.553-51.779 14.481a84.797 84.797 0 0 1-8.222-1.296zM56.907 87.315c-20.737 16.725-20.164 51.963 1.278 78.55s55.76 34.611 76.497 17.886c9.631-7.768 15.003-19.911 15.125-34.193.13-15.326-5.695-31.079-16.403-44.357-12.451-15.439-29.243-24.617-45.196-26.326-11.521-1.235-22.605 1.427-31.301 8.44z"
    />
    <path
      fill="#ffc751"
      d="M58.185 165.864c-17.889-22.182-21.231-50.367-9.806-68.812 3.163-5.106 2.582-11.687-1.317-16.256-6.302-7.385-17.932-6.354-22.966 1.947-6.701 11.052-10.319 24.193-10.441 38.524-.187 21.816 7.852 43.925 22.635 62.255a107.366 107.366 0 0 0 8.201 9.064c6.451 6.357 17.092 5.274 21.988-2.346l.143-.223c3.521-5.476 2.595-12.616-2.07-17.158a78.701 78.701 0 0 1-6.367-6.995z"
    />
    <path
      fill="#ffe059"
      d="M42.274 129.872c-.961-7.255-.608-14.339 1.063-20.83 1.693-6.576-1.408-13.434-7.573-16.281-8.041-3.714-17.524.754-19.697 9.341-1.536 6.069-2.354 12.488-2.411 19.166a88.188 88.188 0 0 0 .754 12.221c1.214 9.259 10.885 14.824 19.429 11.057l.207-.091c5.671-2.501 9.041-8.439 8.228-14.583z"
    />
    <path
      fill="#ffaf40"
      d="M411.439 220.125c-19.341 2.072-37.48-2.949-51.779-14.481-16.309-13.153-25.398-32.987-25.594-55.847-.187-21.816 7.853-43.926 22.636-62.255s34.688-30.868 56.048-35.307c22.381-4.65 43.692.031 60.001 13.184s25.399 32.987 25.594 55.848c.187 21.816-7.852 43.925-22.635 62.255-14.783 18.33-34.689 30.869-56.049 35.307a84.797 84.797 0 0 1-8.222 1.296zm12.354-141.251c-15.953 1.709-32.745 10.887-45.196 26.326-10.708 13.278-16.534 29.031-16.403 44.357.122 14.282 5.494 26.425 15.125 34.193 20.738 16.725 55.055 8.701 76.497-17.886s22.015-61.824 1.278-78.55c-8.697-7.012-19.781-9.674-31.301-8.44z"
    />
    <path
      fill="#ffc751"
      d="M472.751 65.42c-9.595-7.739-20.924-12.533-33.184-14.224-9.844-1.357-17.87 7.817-15.297 17.414l.074.276c1.428 5.327 5.853 9.385 11.314 10.154 7.13 1.005 13.787 3.719 19.435 8.274 20.738 16.725 20.164 51.963-1.278 78.55a78.736 78.736 0 0 1-8.445 8.95c-5.032 4.554-6.102 12.03-2.391 17.712l.154.235c4.702 7.203 14.78 8.631 21.157 2.859 4.066-3.68 7.89-7.722 11.419-12.098 14.783-18.329 22.821-40.439 22.635-62.255-.195-22.86-9.283-42.694-25.593-55.847z"
    />
    <path
      fill="#ffe059"
      d="M482.043 118.659c9.173 0 15.982-8.671 13.62-17.535-3.809-14.294-11.625-26.601-22.913-35.704a66.404 66.404 0 0 0-8.86-6.038c-6.684-3.802-15.186-1.298-18.931 5.418l-.142.255c-3.712 6.652-1.377 15.068 5.244 18.834a37.681 37.681 0 0 1 5.031 3.426c6.644 5.359 11.093 12.621 13.371 20.888 1.695 6.146 7.205 10.456 13.58 10.456z"
    />
    <path
      fill="#ffc751"
      d="M281.277 274.682h-50.553s-2.446 109.954-64.97 141.109h180.492c-62.524-31.154-64.969-141.109-64.969-141.109z"
    />
    <path
      fill="#ffaf40"
      d="M262.12 341.61c0-25.82-12.39-48.75-31.56-63.15.13-2.44.16-3.78.16-3.78h50.56s2.44 109.96 64.97 141.11H210.31c30.23-11.07 51.81-40.1 51.81-74.18z"
    />
    <path
      fill="#706c64"
      d="M385.801 503.031H126.199v-85.529c0-10.947 8.874-19.821 19.821-19.821h219.96c10.947 0 19.821 8.874 19.821 19.821z"
    />
    <path
      fill="#595751"
      d="M365.98 397.68h-44.11c-1.118 43.456-30.575 79.868-70.58 91.441v13.909H385.8V417.5c0-10.95-8.87-19.82-19.82-19.82z"
    />
    <path
      fill="#44433f"
      d="M385.8 417.5v85.53H281.57v-13.91c47.91-.76 95.09-35.07 100.14-83.68 2.57 3.34 4.09 7.52 4.09 12.06z"
    />
    <circle cx={151.831} cy={420.582} r={7.477} fill="#8f8b81" />
    <circle cx={151.831} cy={418.439} r={5.334} fill="#b5b1a4" />
    <circle cx={360.169} cy={420.582} r={7.477} fill="#8f8b81" />
    <circle cx={360.169} cy={418.439} r={5.334} fill="#b5b1a4" />
    <circle cx={151.831} cy={464.36} r={7.477} fill="#8f8b81" />
    <circle cx={151.831} cy={462.217} r={5.334} fill="#b5b1a4" />
    <circle cx={360.169} cy={464.36} r={7.477} fill="#8f8b81" />
    <circle cx={360.169} cy={462.217} r={5.334} fill="#b5b1a4" />
    <path
      fill="#ffe059"
      d="M256 292.699c-74.854 0-135.536-60.681-135.536-135.535V16.311h271.071v140.853c0 74.854-60.681 135.535-135.535 135.535z"
    />
    <path
      fill="#ffaf40"
      d="M285.03 309.3c-9.35 2.04-19.07 3.12-29.03 3.12s-19.68-1.08-29.03-3.12c3.43-20.04 3.75-34.62 3.75-34.62h50.56s.32 14.58 3.75 34.62z"
    />
    <path fill="#ffc751" d="M120.465 16.311h271.071v51.61H120.465z" />
    <path
      fill="#ffc751"
      d="M391.54 16.31v140.85c0 74.86-60.69 135.54-135.54 135.54-45.77 0-86.24-22.69-110.78-57.43 17.97 7.54 37.71 11.7 58.42 11.7 83.5 0 151.2-67.69 151.2-151.19 0-29.15-8.25-56.38-22.55-79.47h59.25z"
    />
    <path
      fill="#fff370"
      d="M389.966 44.401H122.034c-10.495 0-19.002-8.508-19.002-19.002v-6.396C103.032 8.508 111.539 0 122.034 0h267.932c10.495 0 19.003 8.508 19.003 19.002v6.396c-.001 10.495-8.508 19.003-19.003 19.003z"
    />
    <path
      fill="#fff7c7"
      d="M309.828 0H168.632c-12.261 0-22.201 9.94-22.201 22.201 0 12.261 9.94 22.201 22.201 22.201h141.196c12.261 0 22.201-9.94 22.201-22.201C332.028 9.94 322.089 0 309.828 0z"
    />
    <path
      fill="#fffdf3"
      d="M253.009 0h-46.928C193.82 0 183.88 9.94 183.88 22.201c0 12.261 9.94 22.201 22.201 22.201h46.928c12.261 0 22.201-9.94 22.201-22.201C275.209 9.94 265.27 0 253.009 0z"
    />
    <path
      fill="#8f8b81"
      d="M404.314 496.418c0-8.606-6.976-15.582-15.582-15.582H123.268c-8.606 0-15.582 6.976-15.582 15.582 0 8.606 6.976 15.582 15.582 15.582h265.464c8.606 0 15.582-6.976 15.582-15.582z"
    />
    <path
      fill="#b5b1a4"
      d="M285.681 512H149.604c-8.606 0-15.582-6.976-15.582-15.582 0-8.606 6.976-15.582 15.582-15.582h136.077c8.606 0 15.582 6.976 15.582 15.582 0 8.606-6.976 15.582-15.582 15.582z"
    />
    <path
      fill="#d8d4c9"
      d="M182.406 480.835h36.354c8.606 0 15.582 6.976 15.582 15.582 0 8.606-6.976 15.582-15.582 15.582h-36.354c-8.606 0-15.582-6.976-15.582-15.582 0-8.605 6.977-15.582 15.582-15.582z"
    />
    <path
      fill="#8f8b81"
      d="M285.681 428.687h-94.077c-8.606 0-15.582-6.976-15.582-15.582 0-8.606 6.976-15.582 15.582-15.582h94.077c8.606 0 15.582 6.976 15.582 15.582 0 8.606-6.976 15.582-15.582 15.582z"
    />
    <path
      fill="#b5b1a4"
      d="M213.776 397.523h36.354c8.606 0 15.582 6.976 15.582 15.582 0 8.606-6.976 15.582-15.582 15.582h-36.354c-8.606 0-15.582-6.976-15.582-15.582-.001-8.606 6.976-15.582 15.582-15.582z"
    />
    <path
      fill="#fff370"
      d="M140.46 78.719c-11.046 0-20 8.954-20 20v58.44c0 18.368 3.655 35.881 10.276 51.855 4.795 11.567 19.038 15.78 29.397 8.746l.331-.225c7.721-5.242 10.857-15.199 7.273-23.815-4.687-11.267-7.277-23.617-7.277-36.561v-58.44c0-11.045-8.954-20-20-20z"
    />
    <path
      fill="#fff7c7"
      d="M160.46 146.505v-28.537c0-11.046-8.954-20-20-20s-20 8.954-20 20v28.537c0 11.046 8.954 20 20 20s20-8.954 20-20z"
    />
  </svg>
);
