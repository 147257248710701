function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = '/';

export const PATH_AUTH = {
  login: '/login',
};

export const PATHS = {
  root: ROOTS,
  games: path(ROOTS, 'profile'),
  genX: path(ROOTS, 'genx-profile'),
  banner: path(ROOTS, 'banner'),
  rewards: path(ROOTS, 'rewards'),
  events: path(ROOTS, 'events'),
  swap: path(ROOTS, 'swap'),
  staking: path(ROOTS, 'staking'),
  mint: path(ROOTS, 'mint'),
  mintGenx: path(ROOTS, 'mint-genx'),
  transfer: path(ROOTS, 'transfer'),
  faq: path(ROOTS, 'faq'),
  activationCode: path(ROOTS, 'sponsors'),
  launchPad: path(ROOTS, 'launch-pad'),
  page403: path(ROOTS, '403'),
};
