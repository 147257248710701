import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Button, DialogActions } from '@mui/material';
import { Modal } from '../../../..';
import {
  closeTweetMessageModal,
  selectIsTweetMessageModalOpen,
  selectExtraId,
  selectTweetMessage,
  selectType,
} from '../../../../../redux/slices/tasksSlice';
import { replyToTweet, repost, sharePost } from '../../lib';
import { ShareIcon } from '../../../../../assets/svg';
import { useCopyToClipboard } from '../../../../../hooks';

const MAP_TYPE_TO_CONTENT = {
  tweet: 'Share this text as a post at your X Platform:',
  retweet: 'Repost this at your X Platform:',
  reply: 'Comment this at your X Platform:',
};

const MAP_TYPE_TO_BUTTON_CONTENT = {
  tweet: 'Share',
  retweet: 'Repost',
  reply: 'Comment',
};

export function TasksModal({ twitterNft }) {
  const isTweetMessageModalOpen = useSelector(selectIsTweetMessageModalOpen);
  const tweetMessage = useSelector(selectTweetMessage);
  const type = useSelector(selectType);
  const extraId = useSelector(selectExtraId);
  const dispatch = useDispatch();
  const { copy, isCopied } = useCopyToClipboard(tweetMessage);

  function handleHref() {
    if (type === 'tweet') {
      return sharePost(tweetMessage);
    }
    if (type === 'retweet') {
      return repost({ extraId, tweetMessage });
    }

    return replyToTweet({ extraId, comment: tweetMessage });
  }

  return (
    <Modal
      title="Tweet Message"
      open={isTweetMessageModalOpen}
      onClose={() => dispatch(closeTweetMessageModal())}
    >
      <p className="mb-4">{MAP_TYPE_TO_CONTENT[type]}</p>
      <div className="bg-black p-4 bg-black/70 rounded-xl select-all text-white">
        <div className=" flex gap-x-3">
          <Avatar className="w-10 h-10" src={twitterNft} />
          <p className="leading-relaxed font-semibold">{tweetMessage}</p>
        </div>

        <div className="flex justify-end">
          <Button
            size="small"
            variant="outlined"
            className="!text-white !border !border-white h-8"
            onClick={() => copy()}
          >
            {isCopied ? 'copied !' : 'copy'}
          </Button>
        </div>
      </div>
      <DialogActions className="!pb-0 flex gap-x-3 items-center">
        <Button
          disableRipple
          className="!text-white !bg-transparent"
          onClick={() => dispatch(closeTweetMessageModal())}
        >
          Back
        </Button>
        <Button
          LinkComponent={Button}
          rel="noreferrer noopener"
          target="_blank"
          href={handleHref()}
          variant="contained"
          className="!bg-primary min-w-[8.5rem] py-3 rounded-xl px-1"
          onClick={() => dispatch(closeTweetMessageModal())}
          startIcon={<ShareIcon className="!w-4 !h-4" />}
        >
          {MAP_TYPE_TO_BUTTON_CONTENT[type]}
        </Button>
      </DialogActions>
    </Modal>
  );
}
