import { Card } from '@mui/material';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { MintCountdownTimer } from '../../../MintCountdownTimer';

const OFFER_DATE = new Date('October 14 00:00:00 2024 UTC');

export function MintOfferTimer() {
  const [isFinished, setIsFinished] = useState(false);

  const { formatNumber } = useIntl();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isVisible = useMemo(
    () => OFFER_DATE.getTime() > Date.now(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFinished]
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Card
      className="flex flex-col gap-y-4 max-w-5xl items-center mx-auto w-full p-10"
      key={`${isVisible}`}
    >
      <h3 className="text-center md:text-lg">
        The price of Gen X will rise to 900 USD tomorrow, October 14th, at
        midnight.
      </h3>
      <MintCountdownTimer
        date={OFFER_DATE}
        className="text-2xl md:!text-3xl self-center mt-4"
        timeRenderer={(value) =>
          formatNumber(value, { minimumIntegerDigits: 2 })
        }
        onComplete={() => setIsFinished(true)}
      />
    </Card>
  );
}
