import queryString from 'query-string';

export function replyToTweet({ extraId, comment }) {
  const baseUrl = 'https://x.com/intent/tweet';
  const query = {
    in_reply_to: extraId,
    text: comment || '',
  };
  return queryString.stringifyUrl({ url: baseUrl, query });
}
