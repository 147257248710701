import { Avatar } from '@mui/material';
import React from 'react';
import { WUSDCoinIcon } from '../../../assets/svg';

export function WusdCoin({
  width = '1.5rem',
  height = '1.5rem',
  sx,
  ...props
}) {
  return <Avatar src={WUSDCoinIcon} sx={{ width, height, ...sx }} {...props} />;
}
