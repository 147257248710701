import { useAccount, useContractRead } from 'wagmi';
import { isUndefined } from 'lodash';
import { utils } from 'ethers';
import { useEffect, useState } from 'react';
import {
  investMainnetConfig,
  PANCAKESWAP_ROUTER_ABI as abi,
  PANCAKESWAP_ROUTER_ADDRESS as address,
} from './constants';

export function useScores() {
  const { address } = useAccount();

  const { data, isLoading } = useContractRead({
    ...investMainnetConfig,
    functionName: 'getScores',
    args: [address],
    enabled: Boolean(address),
    watch: true,
  });

  return {
    scores: !isUndefined(data) ? Number(utils.formatUnits(data, 6)) : undefined,
    isScoresLoading: isLoading,
  };
}

export function useFuCoinPrice() {
  const [price, setPrice] = useState(0);

  const amountIn = utils.parseUnits('1', 18); // Parse 1 unit with 18 decimals

  const { data, isError } = useContractRead({
    address,
    abi,
    watch: true,
    functionName: 'getAmountsOut',
    args: [
      amountIn,
      [
        '0xed6af21df463c7f116a6d7d687a4c190c4cf7586', // FUCOIN
        '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', // WBNB
        '0x55d398326f99059ff775485246999027b3197955', // USDT
      ],
    ],
    onError: () => setPrice(0), // Set price to 0 on error
  });

  useEffect(() => {
    if (data && !isError) {
      setPrice(Number(utils.formatUnits(data[2], 18))); // Format result as a number with 18 decimals
    }
  }, [data, isError]);

  return {
    price,
  };
}
