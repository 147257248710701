import { useSelector } from 'react-redux';
import { useGetTasks } from '../../../api/queries/getTasks';
import { Status } from './Status';
import { ActiveTasks } from './ActiveTasks';
import { selectCurrentAvatar } from '../../../redux/slices/authSlice';

export function Tasks() {
  const twitterNft = useSelector(selectCurrentAvatar);

  const tasksQuery = useGetTasks();

  return (
    <div className="flex flex-col lg:flex-row gap-4">
      <ActiveTasks tasksQuery={tasksQuery} twitterNft={twitterNft} />
      <Status tasksQuery={tasksQuery} twitterNft={twitterNft} />
    </div>
  );
}
