import CheckIcon from '@mui/icons-material/Check';
import { Button } from '@mui/material';
import clsx from 'clsx';
import { FormattedNumber } from 'react-intl';
import { BwjCoin, WusdCoin } from '../../../..';
import { useBreakpointUp } from '../../../../../hooks';
import { BWJ_TOKEN } from '../../../constants';

export function PaymentButton({
  onClick,
  isSelected,
  tokenName,
  wusdAmount,
  ...props
}) {
  const isUpSm = useBreakpointUp('sm');
  const widthAndHeight = {
    width: isUpSm ? '1.5rem' : '1.2rem',
    height: isUpSm ? '1.5rem' : '1.2rem',
  };

  // const { data } = useGetBwjInUsd({
  //   bwjAmount,
  // });
  // const bwjInDollar = data ? formatBigNumber(data?.[1]) : 0;

  return (
    <Button
      onClick={onClick}
      variant="outlined"
      className={clsx(
        'flex-1 bg-transparent text-white  text-sm font-bold sm:text-base',
        isSelected
          ? '!border-white !border-opacity-30 '
          : '!border-white !border-opacity-5 '
      )}
      startIcon={
        tokenName === BWJ_TOKEN ? (
          <BwjCoin {...widthAndHeight} />
        ) : (
          <WusdCoin {...widthAndHeight} />
        )
      }
      endIcon={
        <div className="flex items-center">
          {isSelected && (
            <CheckIcon
              fontSize={isUpSm ? 'medium' : 'small'}
              className="text-success-light"
            />
          )}
        </div>
      }
      {...props}
    >
      {tokenName}
    </Button>
  );
}
